import '@root/styles/globals.css';
import type { AppProps } from 'next/app';
() => {
	if (typeof window === 'undefined') {
		return;
	}

	const BANNER = `%c
                                                        
   ______           _                                   
  |  ____|         | |                                  
  | |__   _ __ ___ | |__   __ _ _ __ __ _  ___          
  |  __| | '_ \` _ \\| '_ \\ / _\` | '__/ _\` |/ _ \\         
  | |____| | | | | | |_) | (_| | | | (_| | (_) |        
  |______|_| |_| |_|_.__/ \\__,_|_|  \\__, |\\___/         
                                     __/ |              
                                    |___/               
                                                        
  Managing 3rd party vendor Javascript.                 
                                                        
`;
	console.log(
		BANNER,
		'display:block; color:white; line-height: 1.35; background-color:#5b45b4; background:linear-gradient(90deg,#443285 0%,#5b45b4 12%, #5b45b4 25%,#715cc1 50%, #5b45b4 75%); padding:0px;  padding:0; font-weight:800; font-family:monospace; margin-bottom:0px; min-width:60%;',
	);
	console.log(
		'%cPsst! Want to chat with a real dev? Email "devs@embargokit.com" and we\'ll get back to you by email or video chat. 🤓',
		'color:#5b45b4; font-weight:800; font-family:monospace; margin-top: 10px; margin-bottom:0px; display: block;',
	);

	const clearForRendering = () => {
		const noScript = document.querySelector('noscript');
		if (noScript) {
			noScript.parentElement!.removeChild(noScript);
		}
		if (
			window.navigator &&
			window.navigator.userAgent &&
			/bot|googlebot|bingbot|crawler|spider|robot|crawling/i.test(
				window.navigator.userAgent,
			)
		) {
			Array.from(
				document.querySelectorAll(
					'#intercom-container, #intercom-frame',
				),
			).forEach(function (el) {
				el.parentElement!.removeChild(el);
			});
		}
	};

	clearForRendering();
	window.setTimeout(clearForRendering, 500);
	window.setTimeout(clearForRendering, 1000);
	window.setTimeout(clearForRendering, 2000);
	window.setTimeout(clearForRendering, 4000);
	window.setTimeout(clearForRendering, 8000);
	window.setTimeout(clearForRendering, 16000);
};

export default function App({ Component, pageProps }: AppProps) {
	return <Component {...pageProps} />;
}
